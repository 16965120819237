<template>
  <div>
    <div class="operationControl">
      <div class="searchbox"></div>
      <div class="btnBox">
        <el-button type="primary" class="bgc-bv" round @click="handleAdd()"
          >新增题库</el-button
        >
      </div>
    </div>
    <div class="framePage-scroll">
      <div class="ovy-a">
        <el-table
          ref="multipleTable"
          :data="tableData"
          :height="tableHeight"
          size="small"
          tooltip-effect="dark"
          style="width: 100%"
          :header-cell-style="tableHeader"
          stripe
        >
          <el-table-column
            label="序号"
            align="center"
            type="index"
            :index="indexMethod"
          />
          <el-table-column
            label="题库名称"
            align="center"
            show-overflow-tooltip
            prop="questionBankName"
          />
          <el-table-column
            label="题库数量"
            align="center"
            show-overflow-tooltip
            prop="questionBankNum"
          />

          <el-table-column label="操作" align="center">
            <div slot-scope="scope" class="flexcc">
              <el-button
                type="text"
                size="mini"
                style="padding:0 5px"
                @click="see(scope.row)"
                >详情</el-button
              >
              <el-button
                type="text"
                size="mini"
                style="padding:0 5px"
                @click="handleDel(scope.row.planResourceId)"
                >删除</el-button
              >
            </div>
          </el-table-column>
          <Empty slot="empty" />
        </el-table>
      </div>
    </div>
    <PageNum :apiData="apiData" @sizeChange="sizeChange" @getData="getData" />
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import { mapGetters } from "vuex";
import moment from "moment";
export default {
  name: "appraisalBindCourseList",
  components: {
    Empty,
    PageNum,
  },
  mixins: [List],
  data() {
    return {
      delectId: "",
      userJson: {},
      queryStr: "",
      dialogVisible: false,
      dialogExamination: false,
      dialogMytestpaper: false,
      activeName: "first",
      ruleForm: {
        source: "",
        Auditstatus: "",
        Salesstatus: "",
        Trainingtype: "",
        Creattime: "",
      },
      projectId: "",
      affirm: 0,
      paperTotalScore: "",
      paperName: "",
      paperScore: "",
      paperStartTime: "",
      paperEndTime: "",
      projectCourseId: "",
      paperId: "",
      projectSource: "",
      countdown: "10",
      deleteConfirmation: true,
      timer: null,
      trainTypeId: "",
      appraisalJson: {},
    };
  },
  computed: {},
  methods: {
    init() {
      this.appraisalJson = JSON.parse(sessionStorage.getItem("appraisalJson"));
      this.getTableHeight();
      this.getData(-1);
    },
    getData(pageNum) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
        planId: this.appraisalJson.planId,
      };
      this.doFetch({
        url: "/biz/evaluate/plan/bank/page",
        params,
        pageNum,
      });
    },
    /* 查看 */
    see(row) {
      this.$router.push({
        path: "/web/appraisalInstitution/appraisaQuestionBankDetail",
        query: {
          questionBankId:row.questionBankId,
          questionBankName:row.questionBankName,
          categoryName:row.trainTypeName
        },
      });
    },
    
    // 创建课程、查看编辑课程
    handleAdd() {
      this.$router.push({
        path: "/web/appraisalInstitution/appraisalNoBindQuestionBankList",
        query: {
          planId: this.appraisalJson.planId,
        },
      });
    },
    //删除
    handleDel(planResourceId) {
      this.doDel({
        // url: "/evaluate/exam/bank/delete",
        url: "/biz/evaluate/plan/resource/delete",
        msg: "是否确认删除？",
        ps: {
          type: "post",
          data: { planResourceId},
        },
      });
    },
    //取消
    cancle() {
      this.dialogExamination = false;
    },
    canclepaper() {
      this.dialogExamination = false;
    },
    doClose() {
      this.dialogExamination = false;
    },
    getTableHeight(opDom = true, page = true) {
      let tHeight =
        window.innerHeight - (3 + 1.35 + 3.25 + 0.675 + 2 + 1.35) * 16;
      if (opDom) {
        tHeight -= 40 + 0.675 * 16 + 1 + 40 + 15;
      }
      if (page) {
        tHeight -= 32;
      }
      this.tableHeight = tHeight;
    },
  },
};
</script>
<style lang="less">
.listWrap {
  .el-icon-arrow-down {
    font-size: 12px;
    &:before {
      content: "\e6df" !important;
    }
  }
  .el-tabs {
    flex: 1;
    display: flex;
    flex-direction: column;
    .el-tabs__content {
      flex: 1;
    }
  }
}
.btnBox {
  .el-button.is-disabled,
  .el-button.is-disabled:focus,
  .el-button.is-disabled:hover {
    background: #a9a9a9;
    border-color: #a9a9a9;
    color: #fff;
  }
}
.confirmButtonClass {
  color: #fff !important;
  background-color: #5c6be8 !important;
  border-color: #5c6be8 !important;
}
.del-tishi {
  .el-dialog__header {
    background: #ffffff;
    text-align: left;
  }
  .el-dialog__title {
    color: #333;
  }
  .el-dialog__body {
    .theCountdown {
      color: red;
      font-size: 16px;
    }
  }
  .el-dialog__headerbtn {
    .el-dialog__close {
      color: black;
    }
  }
}
</style>
