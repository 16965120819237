<template>
  <div class="framePage-scroll">
    <div class="ovy-a">
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="120px"
        :disabled="appraisalJson.examPeopleJoin > 0 "
      >
       
        <el-form-item label="鉴定考试工种：" prop="compOccupationId">
         <el-select
          v-model="ruleForm.compOccupationId"
          placeholder="请选择"
          clearable
          filterable
          size="small"
        >
          <el-option
            v-for="item in occupation"
            :key="item.compOccupationId"
            :label="item.showName"
            :value="item.compOccupationId"
          >
          </el-option>
        </el-select>
      </el-form-item>
       <el-form-item
                label="考试封面："
                prop="examPlanIconUrl"
                class="form-item"
              >
                <el-upload
                  :on-change="handleAvatarSuccess2"
                  :before-upload="beforeAvatarUpload2"
                  :http-request="$requestMine"
                  :show-file-list="false"
                  class="img-el-upload"
                  action
                  accept="image/png, image/gif, image/jpg, image/jpeg"
                    
                >
                  <el-image
                    :src="
                      ruleForm.examPlanIconUrl || require('@/assets/develop.png')
                    "
                    fit="contain"
                    class="imgCenter"
                  ></el-image>
                </el-upload>
              </el-form-item>
       <el-form-item label="报名时间：" prop="signUpTime">
          <el-date-picker
            size="small"
            v-model="ruleForm.signUpTime"
            type="datetimerange"
            style="width:100px"
            range-separator="至"
            start-placeholder="报名开始日期"
            end-placeholder="报名结束日期"
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="考试人数：" prop="examPeople">
          <el-input-number
            v-model="ruleForm.examPeople"
            @change="handleChange"
            :min="1"
            :max="999"
            size="small"
          ></el-input-number>
        </el-form-item>
        <el-row :gutter="10">
        <el-col :span="4">
          <el-form-item label-width="30px" prop="examOnlinePay">
            <el-checkbox v-model="ruleForm.examOnlinePay">线上缴费</el-checkbox>
          </el-form-item>
        </el-col>
        <el-col :span="18" v-if="ruleForm.examOnlinePay">
          <el-form-item label="缴费金额：" prop="payAmount">
            <el-input-number v-model="ruleForm.payAmount"  @change="handleChange" :min="1" size="small"></el-input-number>&ensp;元
          </el-form-item>
        </el-col>
      </el-row>
      </el-form>
      <div class="bottom-btn">
        <el-button @click="cancle" class="bgc-bv">取消</el-button>
        <el-button @click="seveData('Form')" class="bgc-bv">保存</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import UploadPic from "@/mixins/UploadPic";
import DatePicker from "@/mixins/DatePicker";
import pdf from "pdfobject";
import { mapGetters } from "vuex";
import List from "@/mixins/List";
export default {
  name: "questionbankInformation",
  components: {},
  data() {
    return {
      //基本设置
      ruleForm: {
        compOccupationId: "", //考试工种
        signUpTime: "", //报名时间
        examPlanIconUrl:'',
        examOnlinePay:0,//线上缴费
        payAmount:null,//缴费金额
        examPeople: "", //考试人数
      },
      rules: {
        compOccupationId: [
          {
            required: true,
            message: "请选择鉴定考试工种",
            trigger: "change",
          },
        ],
        examPlanIconUrl: [
          {
            required: true,
            message: "请上传考试封面",
            trigger: "change",
          },
        ],
        examPeople: [
          {
            required: true,
            message: "请输入考试人数",
            trigger: "blur",
          },
        ],
      },
      //考试工种
      occupation: [],
      appraisalJson: {},
    };
  },
  computed: {},
  created() {
    this.appraisalJson = JSON.parse(sessionStorage.getItem("appraisalJson"));
    this.appraisalJson.examPeopleJoin = Number(this.appraisalJson.examPeopleJoin)
    this.getOccupation();
    this.getInformation(this.appraisalJson.planId);
  },
  methods: {
      //考试封面
    handleAvatarSuccess2(res) {
      let extension = res.name.substring(res.name.lastIndexOf(".") + 1);
      let formData = new FormData();
      formData.append("folder ", "COURSE_WARE");
      formData.append("file ", res.raw);
      formData.append("fileType ", extension);
      const isLt2M = res.size / 1024 / 1024 < 1;
      if (!isLt2M) {
        this.$message.error("上传图片大小不能超过 1MB!");
      } else if (
        ["image/png", "image/gif", "image/jpg", "image/jpeg"].indexOf(
          res.raw.type
        ) === -1
      ) {
        this.$message.error("请上传正确的图片格式");
        return false;
      } else {
        this.$Postformat("/sys/upload", formData)
          .then((result) => {
            this.ruleForm.examPlanIconUrl = result.data.fileURL;
            this.ruleForm.examPlanIcon = result.data.fileKey;
          })
          .catch(() => {
            setTimeout(() => {
              this.$message({
                type: "warning",
                message: "上传失败",
              });
            }, 300);
          });
      }
    },
    /* 鉴定考试工种 */
    getOccupation() {
        this.$post('/biz/evaluate/company/occupation/own',{}).then(ret => {
            this.occupation = ret.data.list || []
        }).catch(err => {
            return;
        })
    },
    /* 基本信息回显数据 */
    getInformation(planId) {
      this.$post("/biz/evaluate/plan/getInfo", { planId })
        .then((ret) => {
          let retData = ret.data;
          console.log(retData);
          this.ruleForm = {
            ...retData,
            examOccupationId: retData.examOccupationId,
            examLevel: retData.examLevel,
            signUpTime: [
              retData.examStartTime.replaceAll("/", "-"),
              retData.examEndTime.replaceAll("/", "-"),
            ],
            examPlanIcon:retData.examPlanIcon,
            examPlanIconUrl:retData.examPlanIconKey,
            payAmount:retData.payAmount || 1
          };
        })
        .catch((err) => {
          return;
        });
    },
    seveData(ruleForm) {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          const that = this;
          let parmar = {
            compOccupationId: that.ruleForm.compOccupationId,
            examPeople:that.ruleForm.examPeople,
            examPlanIcon:that.ruleForm.examPlanIcon,
            examStartTime:that.ruleForm.signUpTime[0],
            examEndTime:that.ruleForm.signUpTime[1],
            examOnlinePay:that.ruleForm.examOnlinePay
          };
          if(parmar.examOnlinePay){
            parmar.payAmount = that.ruleForm.payAmount
          }
          if (that.appraisalJson.planId) {
            parmar.planId = that.appraisalJson.planId;
          }
          that
            .$post("/biz/evaluate/plan/update", parmar)
            .then((ret) => {
              if (ret.status == 0) {
                that.$message({
                  message: "保存成功",
                  type: "success",
                });
                that.$router.push({
                  name: "appraisalInstitution/AppraisalExaminationPlanList",
                  params: {
                    refresh: true,
                    isAdd: false,
                  },
                });
              }
            })
            .catch((err) => {
              return;
            });
        }
      });
    },
    cancle() {
      this.$router.back();
    },
  },
};
</script>
<style lang="less">
.el-textarea {
  .el-textarea__inner {
    resize: none;
  }
}
</style>
<style lang="less" scoped>
.bottom-btn {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px 0;
}
</style>
