<template>
  <div class="pageContol listWrap templateList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;" @click="goHerf()">考试计划</a>
          <i>></i>
          <a href="javascript:;" @click="init" class="cur-a">{{appraisalJson.examOccupationName}}-详情</a>
        </span>
      </div>
      <div class="framePage-body framePage-tabs">
        <el-tabs v-model="activeName" :before-leave="beforeTabLeave" @tab-click="tabChange">
          <el-tab-pane label="考试信息" name="first">
            <appraisalExaminationPlanEdit ref="first" />
          </el-tab-pane>
          <el-tab-pane label="已绑定课程列表" name="second">
            <appraisalBindCourseList ref="second" />
          </el-tab-pane>
          <el-tab-pane label="已绑定题库列表" name="three">
            <appraisalBindQuestionBankList ref="three" />
          </el-tab-pane>
          <el-tab-pane label="学员列表" name="four">
            <appraisalStudentList ref="four" />
          </el-tab-pane>
          <!-- <el-tab-pane label="已绑定题库列表" name="three">
            <changchunStudentList ref="three" v-if="projectSource == '20'" />
            <shanxiStudentList ref="three" v-else-if="projectSource == '50'"/>
            <StudentList ref="three" v-else />
          </el-tab-pane>
          <el-tab-pane label="学员列表" name="four">
            <changchunStudentList ref="three" v-if="projectSource == '20'" />
            <shanxiStudentList ref="three" v-else-if="projectSource == '50'"/>
            <StudentList ref="three" v-else />
          </el-tab-pane> -->
        </el-tabs>
      </div>
    </div>
  </div>
</template>
<script>
import List from "@/mixins/List";
import appraisalExaminationPlanEdit from "@/views/appraisalInstitution/AppraisalExamination/appraisalExaminationPlanEdit.vue";
import appraisalBindCourseList from "@/views/appraisalInstitution/AppraisalExamination/appraisalBindCourseList.vue";
import appraisalBindQuestionBankList from "@/views/appraisalInstitution/AppraisalExamination/appraisalBindQuestionBankList.vue";
import appraisalStudentList from "@/views/appraisalInstitution/AppraisalExamination/appraisalStudentList.vue";
import { mapGetters } from "vuex";
export default {
  name: "ClassdetailsList",
  components: {
    appraisalExaminationPlanEdit,
    appraisalBindCourseList,
    appraisalBindQuestionBankList,
    appraisalStudentList
    // StudentList,
    // changchunStudentList,
    // shanxiStudentList
  },
  mixins: [List],
  data() {
    return {
      activeName: "",
      appraisalJson: "",
    };
  },
  mounted() {},
  watch: {
    activeName: function(nval, oval) {
      if (nval && nval != "first") {
        this.$refs[nval].getData();
      }
    }
  },
  computed: {
    ...mapGetters({
      userJson: "getUser"
    })
  },
  methods: {
    init() {
       this.appraisalJson = JSON.parse(sessionStorage.getItem("appraisalJson"));
      const query = this.$route.query;
      if (query.active) {
        this.activeName = query.active;
      }
    },
    tabChange(tab) {
      this.activeName = tab.name;
      // if (tab.name == "three") {
      //   this.$refs.three.openOnce();
      // }
    },
    beforeTabLeave(newName) {
      this.$router.replace({
        path: this.$route.path,
        query: { active: newName }
      });
    },
    goHerf() {
          this.$router.back();
        }
  }
};
</script>
<style lang="less" scoped>
.framePage-tabs {
  flex: 1;
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 0%;
  display: flex;
  flex-direction: row;
  overflow: hidden;
  position: relative;
  background-color: #fff;
  padding: 1rem 0.675rem;
  box-sizing: border-box;
}
#pane-first {
  display: flex;
  flex: 1;
  overflow: hidden;
}
</style>
<style lang="less">
.framePage-tabs {
  .el-tabs {
    width: 100%;
  }
  .el-tabs__content {
    overflow-y: auto;
  }
}
</style>
