<template>
  <div>
    <div
      class="operationControl"
      style="display:flex;flex-direction: column;align-items: flex-start"
    >
     
      <div class="searchbox" style="margin-bottom:15px">
        <div title="姓名" class="searchboxItem ci-full">
          <span class="itemLabel">姓名:</span>
          <el-input
            v-model="planUserName"
            type="text"
            size="small"
            placeholder="请输入姓名"
            clearable
          />
        </div>
        <div title="联系方式" class="searchboxItem ci-full">
          <span class="itemLabel">联系方式:</span>
          <el-input
            v-model="mobile"
            type="text"
            size="small"
            placeholder="请输入联系方式"
            clearable
          />
        </div>
        <div class="btnBox">
          <el-button type="primary" class="bgc-bv" round @click="getData()"
            >查询</el-button
          >
          <el-button
          type="primary"
          class="bgc-bv"
          round
          @click="handleAddStudent()"
          >添加学员</el-button
        >
        <el-button
          type="primary"
          class="bgc-bv"
          round
          @click="handleBatchImport()"
          >导入学员</el-button
        >
        <!-- <el-button
          type="primary"
          class="bgc-bv"
          round
          @click="handleBatchImport()"
          >批量生成准考证</el-button
        > -->
        <el-button
          type="primary"
          class="bgc-bv"
          round
          @click="handleBatchSetPayment()"
          >批量设为已缴费</el-button
        >
        <el-button
          type="primary"
          class="bgc-bv"
          round
          @click="handleBatchExport()"
          >导出学员信息</el-button
        >
        </div>
      </div>
    </div>
    <div class="framePage-scroll">
      <div class="ovy-a">
        <el-table
          ref="multipleTable"
          :data="tableData"
          :height="tableHeight"
          size="small"
          tooltip-effect="dark"
          style="width: 100%"
          :header-cell-style="tableHeader"
          row-key="planUserId"
          @selection-change="checkListData"
          stripe
        >
          <el-table-column
            type="selection"
            width="50px"
            align="center"
            :reserve-selection="true"
            :selectable="selectable"
          ></el-table-column>
          <el-table-column
            label="序号"
            align="center"
            type="index"
            :index="indexMethod"
          />
          <el-table-column
            label="姓名"
            align="center"
            show-overflow-tooltip
            prop="userName"
          />
          <el-table-column
            label="联系方式"
            align="center"
            show-overflow-tooltip
            prop="mobile"
          />
          <el-table-column
            label="工种"
            align="center"
            show-overflow-tooltip
            prop="compOccupationName"
          />
          <el-table-column
            label="级别"
            align="center"
            show-overflow-tooltip
            prop="occupationLevel"
          />
          <el-table-column
            label="报名方式"
            align="center"
            show-overflow-tooltip
            prop="applyType"
          >
            <template slot-scope="scope">
              {{scope.row.applyType}}
              <!-- {{ $setDictionary("EVALUATE_APPLY_TYPE", scope.row.applyType) }} -->
            </template>
          </el-table-column>
         <el-table-column
            label="缴费方式"
            align="center"
            show-overflow-tooltip
            prop="paymentType"
          >
           <template slot-scope="scope">
             {{scope.row.paymentType}}
              <!-- {{ $setDictionary("EVALUATE_PAYMENT_TYPE", scope.row.paymentType) }} -->
            </template>
         </el-table-column>

          <el-table-column label="是否缴费" align="center">
            <template slot-scope="scope">{{scope.row.payState ? "已缴费" : "未缴费" }}</template>
            <!-- <div slot-scope="scope" class="flexcc">
              <el-button
                type="text"
                size="mini"
                style="padding:0 5px"
                @click="payStateChange(scope.row, 'single')"
                >{{ scope.row.payState ? "已缴费" : "未缴费" }}</el-button
              >
            </div> -->
          </el-table-column>
          <el-table-column
            label="缴费金额"
            align="center"
            show-overflow-tooltip
            prop="paymentAmount"
          />
          <el-table-column label="审核结果" align="center">
             <template slot-scope="scope">
               {{scope.row.auditState}}
              <!-- {{ $setDictionary("EVALUATE_APPLY_TYPE", scope.row.auditState) }} -->
            </template>
          </el-table-column>
          <el-table-column label="操作" align="center">
            <div slot-scope="scope" class="flexcc">
              <el-button
                type="text"
                size="mini"
                style="padding:0 5px"
                @click="handleEditStudent('edit',scope.row.planUserId)"
                >编辑</el-button
              >
              <el-button
                type="text"
                size="mini"
                style="padding:0 5px"
                @click="handleDelete(scope.row.planUserId,scope.row.planId)"
                >删除</el-button
              >
              <el-button
                type="text"
                size="mini"
                style="padding:0 5px"
                @click="handleEditStudent('detail',scope.row.planUserId)"
                >详情</el-button
              >
            </div>
          </el-table-column>
          <Empty slot="empty" />
        </el-table>
      </div>
    </div>
    <PageNum :apiData="apiData" @sizeChange="sizeChange" @getData="getData" />
    <!-- 学员批量导入 -->
    <el-dialog
      :title="'学员批量导入'"
      :visible.sync="dialogVisible"
      width="50%"
      center
      :before-close="doCloseload"
      class="exportdialog"
    >
      <div class="flexdcc">
        <div class="export-box">
          <h1>
            1
            <span>下载并填写导入模板</span>
          </h1>
          <div class="df">
                <span
                  @click="downloadExcel"
                  style="margin-top:20px;margin-right:20px;color:#409eff;cursor: pointer;"
                  >{{templateInfo.templateName}}</span
                >
              </div>
        </div>
        <div class="export-box">
          <h1>
            2
            <span>导入编写好的Excel文档</span>
          </h1>
          <div class="flexdc">
            <div class="df" style="margin:15px 0 10px">
              <el-upload
                class="upload-demo upload-workers"
                :action="actionUrl"
                :on-error="handleError"
                :on-success="handleSuccess"
                :on-change="uploadChange"
                :show-file-list="false"
                :auto-upload="false"
              >
                <el-button class="bgc-bv" style="font-size:12px;width: 80%;"
                  >浏览</el-button
                >
              </el-upload>
              <p v-if="fileName" style="margin-top:10px;margin-left:10px">
                当前选择文件：
                <span style="color:#f46173">{{ fileName }}</span>
              </p>
              <p v-else style="margin-top:10px;margin-left:10px">
                未选择文件
              </p>
            </div>
            <div>
              <el-button
                class="bgc-bv"
                 :disabled="disable"
                size="mini"
                style="margin-top:10px;height: 35px;"
                @click="doExport()"
                >开始导入</el-button
              >
            </div>
             <div style="margin-top: 10px" v-if="progressVal > 0">
              <el-progress
                :text-inside="true"
                :stroke-width="20"
                :percentage="progressVal"
              ></el-progress>
            </div>
          </div>
        </div>
        <div class="export-box">
          <h1>
            3
            <span>导入结果</span>
          </h1>
          <div class="df studentNum" style="margin:1rem 0">
            <span>学员总数量:{{ totalNum }}人;</span>
            <span>成功:{{ correctNum }}人;</span>
            <span>失败:{{ errorNum }}人;</span>
          </div>
          <div>
            <el-button
              class="bgc-bv"
              style="margin-top:15px;height: 35px;"
              size="mini"
              :disabled="errorNum == '0'"
              @click="doExportError"
              >导出错误数据</el-button
            >
          </div>
          <div style="margin-top:15px;color:#DD1D35;" v-if="errorNum != 0">
            有导入失败学员，请导出错误数据，将导入失败学员调整后重新上传
          </div>
        </div>
      </div>
    </el-dialog>
   
   
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import { mapGetters } from "vuex";
import moment from "moment";
export default {
  name: "appraisalBindCourseList",
  components: {
    Empty,
    PageNum,
  },
  mixins: [List],
  data() {
    return {
      planUserName: "",
      mobile:'',
      /* 接参数据 */
      appraisalJson: {},
      /* 模版数据 */
      dialogVisible: false,
      totalNum: 0,
      correctNum: 0,
      errorNum: 0,
      progressVal:0,
      fileKey: "",
      fileName: "",
      multipleTable: [],
        templateInfo:{}, //模版数据
         disable:true,
    };
  },
  computed: {
    ...mapGetters({
      downloadItems: "getDownloadItems",
    }),
  },
  methods: {
    init() {
      this.appraisalJson = JSON.parse(sessionStorage.getItem("appraisalJson"));
      this.appraisalJson.examPeople = Number(this.appraisalJson.examPeople)
      this.appraisalJson.examPeopleJoin = Number(this.appraisalJson.examPeopleJoin)
      this.getTableHeight();
      this.getData(-1);
      // this.getInformation(this.appraisalJson.planId);
    },
    getData(pageNum) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
        planId: this.appraisalJson.planId,
        planUserName: this.planUserName,
        mobile: this.mobile,
      };
      if (this.userName) {
        params.userName = this.userName;
      }
      this.doFetch({
        url: "/biz/evaluate/evaluatePlanUser/planUserList",
        params,
        pageNum,
      });
    },
    //批量导入学员
    handleBatchImport() {
            if(this.appraisalJson.examPeopleJoin  === this.appraisalJson.examPeople) {
        this.$alert('该考试报名学员已满,不可进行导入学员操作!','提示')
      } else {

      this.dialogVisible = true;
      this.getTemplate()
      }
    },
    doCloseload() {
      this.dialogVisible = false;
      this.fileKey = "";
      this.fileName = "";
      this.totalNum = 0;
      this.errorNum = 0;
      this.correctNum = 0;
        this.progressVal = 0;
         this.disable = true;
      this.getData();
    },
    //下载模板
     downloadExcel() {
      window.open(this.templateInfo.templatePath)
     
    },
     //获取模版
    getTemplate() {
      this.$post('/biz/importBiz/template',{enumType:'EVALUATEPLANUSER_TEMPLATE'}).then(ret => {
          console.log(ret);
          this.templateInfo = {
            templateName: ret.data[0].templateName,
            templatePath: ret.data[0].templatePath
          }
      }).catch(err => {
        return
      })
    },
    // 导入学员上传文件
    uploadChange(file) {
      this.fileName = file.name;
      let size = file.size / 1024 / 1024;
      // let fileName = file.name.split(".");
      let extension = file.name.substring(file.name.lastIndexOf(".") + 1);
      const isXLSX = extension === "XLSX";
      const isxlsx = extension === "xlsx";
      const isXLS = extension === "XLS";
      const isxls = extension === "xls";
      if (!isXLSX && !isxlsx && !isXLS && !isxls) {
        this.$message.error("只能上传后缀是.xlsx或者.xls的文件");
        return;
      }
      if (size > 1) {
        this.$message.error("文件大小不能超过1M");
        return;
      }
      let formData = new FormData();
      formData.append("folder ", "USER");
      formData.append("file ", file.raw);
      formData.append("fileType ", extension);

      this.$Postformat("/sys/upload", formData)
        .then((result) => {
          // console.log(result);
          this.fileKey = result.data.fileKey;
          this.disable = false
          // this.fileName = result.data.fileKey;
        })
        .catch((err) => {
          return;
        });
    },
    doExport() {
      if (this.fileKey == "") {
        this.$message({
          message: "请选择文件",
          type: "warning",
        });
      } else {
         let settleContent = {};
               settleContent.planId = this.appraisalJson.planId;
              const parmar = {
                enumType:'EVALUATEPLANUSER_TEMPLATE',
                excelUrl: this.fileKey,
               param: JSON.stringify(settleContent),
              };
              this.$post(
                // "/agency/ma-agency-student/importCompanyUser",
                '/biz/importBiz/importCurrency',
                parmar,
                5000
              )
                .then((res) => {
                   if (res.status == 0) {
                      this.doProgress(res.data);
                  }
                })
                .catch(() => {
                  return;
                });
            }
    },
      //获取导入进度
    /* /biz/projectUser/importProgress */
    doProgress(batchId) {
      this.$post(
        "/biz/importBiz/import/progress",
        { batchId, enumType:'EVALUATEPLANUSER_TEMPLATE' },
        5000
      ).then((ret) => {
        if (!ret.data.status) {
          this.progressVal = ret.data.progress;
          setTimeout(() => {
            this.doProgress(batchId);
          }, 2000);
        } else {
          if (ret.data.message) {
            this.$message({
              type: "error",
              message: '导入失败'
            });
          } else {
            this.totalNum = ret.data.totalNum;
            this.errorNum = ret.data.errorNum;
            this.correctNum = ret.data.correctNum;
            this.importType = ret.data.importType;
            this.batchId = ret.data.batchId;
            this.progressVal = ret.data.progress;
            this.$message({
              type: "success",
              message: "导入成功",
            });
          }
        }
      });
    },
    // //开始导入学员
    //导出错误数据
    doExportError() {
      // console.log(this.batchId);
      if (this.batchId == "" || this.fileKey == "") {
        this.$message({
          message: "还没有导入数据，请先导入文档",
          type: "warning",
        });
      } else {
        this.$post(
          "/biz/importBiz/error/export",
          { batchId: this.batchId, enumType:'EVALUATEPLANUSER_TEMPLATE'  },
          5000
        ).then((res) => {
          if (res.status == 0) {
            window.open(res.data);
          }
        });
      }
    },
    // 勾选列表数据
    checkListData(rowArr) {
      this.multipleTable = rowArr;
    },
    // 批量设置已交费
    handleBatchSetPayment() {
      if (this.multipleTable.length > 0) {
        this.planUserIds = this.multipleTable.map((t) => {
          return t.planUserId;
        });
        let parmar = {
            payState: true,
            planId:this.appraisalJson.planId
          };
         parmar.planUserIds = this.planUserIds;
         this.$post('/biz/evaluate/evaluatePlanUser/setPayPlanUser',
            parmar
          )
            .then((ret) => {
              if (ret.status == 0) {
                this.$message({
                    message: "成功设置已交费",
                    type: "success",
                  });
                this.getData(-1);
                this.$refs.multipleTable.clearSelection();
              }
            })
            .catch((err) => {
              return;
            });
      } else {
        this.$message({
          message: "请至少勾选一项!",
          type: "warning",
        });
      }
    },
    //批量导出学员信息
    handleBatchExport() {
      this.evaluateExamUserIds = this.multipleTable.map((t) => {
        return t.evaluateExamUserId;
      });
      let parmar = {
        planId:this.appraisalJson.planId
      };
      if (this.userName) {
        parmar.userName = this.userName;
      }
      if (this.mobile) {
        parmar.mobile = this.mobile;
      }
      if (this.evaluateExamUserIds.length) {
        parmar.evaluateExamUserIds = this.evaluateExamUserIds;
      }

      this.$post("/biz/evaluate/evaluatePlanUser/exportPlanUserList", parmar).then((res) => {
         if (res.status == "0") {
              let list = [];
              list.push(res.data);
              for (let item of list) {
                console.log(item);
                if (!this.downloadItems.includes(item.taskId)) {
                  this.$store.dispatch("pushDownloadItems", item.taskId);
                } else {
                  this.$message.warning(
                    "[" + item.fileName + "]已经申请下载,请耐心等待"
                  );
                }
              }
            } else {
              this.$message.error(re.message);
            }
      });
    },
    //添加学员
    handleAddStudent() {
      if(this.appraisalJson.examPeopleJoin  === this.appraisalJson.examPeople) {
        this.$alert('该考试报名学员已满,不可进行报名!','提示')
      } else {
          this.$router.push({
        path: "/web/appraisalInstitution/appraisalAddStudent",
        query: {
          stu: "add",
          planId: this.appraisalJson.planId,
        },
      });
      }
    
    },
    //删除学员
    handleDelete(planUserId,planId) {
        this.doDel({
        url: "/biz/evaluate/evaluatePlanUser/deletePlanUser",
        msg: "确定删除该学员吗？",
        ps: {
          type: "post",
          data: { planUserId,planId },
        },
      });
    },
    //详情
    handleEditStudent(stu,planUserId) {
      this.$router.push({
        path: "/web/appraisalInstitution/appraisalAddStudent",
        query: {
          stu,
          planUserId,
          planId: this.appraisalJson.planId,
        },
      });
    },
    getTableHeight(opDom = true, page = true) {
      let tHeight =
        window.innerHeight - (3 + 1.35 + 3.25 + 0.675 + 2 + 1.35 + 4) * 16;
      if (opDom) {
        tHeight -= 40 + 0.675 * 16 + 1;
      }
      if (page) {
        tHeight -= 32;
      }
      this.tableHeight = tHeight;
    },
  },
};
</script>
<style lang="less" scope>
.exportdialog {
  .el-dialog__title {
    font-size: 16px;
  }
  .export-box {
    width: 100%;
    margin-bottom: 20px;
    h1 {
      padding: 0 5px 10px;
      display: flex;
      align-items: flex-end;
      font-size: 40px;
      border-bottom: 1px dashed #ccc;
      span {
        margin-left: 20px;
        font-size: 14px;
      }
    }
    > div {
      padding-left: 30px;
    }
    .el-button.is-disabled,
    .el-button.is-disabled:focus,
    .el-button.is-disabled:hover {
      color: #fff;
      background: #c3c3c3;
      border: none;
    }
  }
  .el-form-item {
    display: flex;
    align-items: center;
  }
}
.exportdialog {
  .upload-workers {
    height: 30px;
    .el-upload {
      height: 30px !important;
      width: 97px;
      border: none !important;
      .el-button {
        padding: 5px 0;
      }
    }
  }
}
.el-textarea__inner {
  min-height: 100px !important;
}
.studentMation {
  display: flex;
  flex-wrap: wrap;
  .el-form-item {
    width: 50%;
    margin-bottom: 5px;
  }
}
</style>
